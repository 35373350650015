import { createApp } from 'vue'
import App from './App.vue'

import router from "@/router"
import { createPinia } from 'pinia'
import piniaPluginPersistedstate from 'pinia-plugin-persistedstate'
import CKEditor from '@ckeditor/ckeditor5-vue';
import '@/assets/css/common.css'
import '@/assets/fonts/suit/woff2/SUIT.css'

const app = createApp(App)

const pinia = createPinia()
pinia.use(piniaPluginPersistedstate)    // pinia 플러그인 추가 (localstorage에 저장)

app.use(pinia)
app.use(router)
app.use(CKEditor)
app.config.productionTip = false
app.config.errorHandler = (err, vm, info) => {
    // 에러 처리
    // `info` 는 Vue 관련 에러 정보 입니다. 예) 라이프사이클 훅에 에러를 발견
    console.error(err)
    console.error(vm)
    console.error(info)
}
// app 전역 설정 변수
app.config.globalProperties.version = '1.0.0.'

// html 공백 유지
app.config.compilerOptions.whitespace = 'preserve'

// html내, vue 템플릿 구분자 변경
//  ['${', '}'] -> ['{{', '}}']
app.config.compilerOptions.delimiters = ['{{', '}}']    // 기본값.

// html 주석 제거한다.. 기본값은 true
app.config.compilerOptions.comments = false

app.mount('#app')
