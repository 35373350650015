/**
 * api 공통
 */
import env from "@/config/env";
import NetworkService from "@/network/net.service";

const NetApiServer = new NetworkService("")

export const ApiService = {


    constructor(){
      NetworkService.value = new NetworkService()
    },
    get(apiName,params){
        let url = makeUrl(apiName)
        return NetApiServer.get(url,params).then(res => {return res})
    },
    post(apiName,params){
        let url = makeUrl(apiName)
        return NetApiServer.post(url, params).then(res => {
            return res
       }).catch(error => {
        return {
            error: true,
            message: error.response ? error.response.data : error.message
        };
    });
    },
    put(apiName,params){
        let url = makeUrl(apiName)
        return NetApiServer.put(url, params).then(res => {
            return res
       })
    },
    delete(apiName,params){
        let url = makeUrl(apiName)
        return NetApiServer.delete(url,params).then(res => {return res})
    },
    getTemplate(params){
       return NetApiServer.get("/api/sample", params).then(res => {
            return res
       })
    },
    postFormWithJson(apiName,params, files){
        let url = makeUrl(apiName)
        return NetApiServer.multipart(url, params, files).then(res => {
            return res
       })
    },
    postForm(apiName, formData) {
        let url = makeUrl(apiName)
        return NetApiServer.multipartForm(url, formData).then(res => {
            return res
       })
    },
    getDownload(apiName) {
        let url = makeUrl(apiName)
        return NetApiServer.download(url).then(res => {return res})
    },
    refreshToken(params) {
        let url = makeUrl('auth/refresh')
        return NetApiServer.refreshToken(url, params).then(res => {
            return res
       })
    }

}

let makeUrl = (apiName) => {
    let url = env.API_URL
    if (url.slice(-1) != '/') url += '/' // 끝에 / 를 추가

    // 시작 부분에 / 를 제거
    if (apiName.slice(0, 1) == '/') url += apiName.slice(1)
    else url += apiName

    return url
}