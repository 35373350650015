<script setup>

</script>

<template>

  <router-view :key="$route.fullPath"></router-view>

</template>

<style scoped>

</style>